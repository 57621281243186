import React, { ReactNode } from 'react';
import styles from './Label.module.css';
import cx from 'classnames';

export interface LabelProps {
  children: ReactNode;
  className?: string;
  htmlFor: string;
}

export function Label({ children, className, htmlFor }: LabelProps) {
  return (
    <label className={cx(styles['Label'], className)} htmlFor={htmlFor}>
      {children}
    </label>
  );
}
