import React, { useState } from 'react';
import { AuthenticationForm } from './AuthenticationForm';
import { useMutation } from '@apollo/react-hooks';
import { useRouter } from 'next/router';
import { ForgotPasswordUpdate_Mutation } from '@netfront/gelada-react-shared';
import { Input, Label } from 'components/Form';
import { useQueryString } from 'lib/hooks/useQueryString';

export function ResetPasswordForm() {
  const { token } = useQueryString();

  const router = useRouter();
  const [password, setPassword] = useState<string>('');

  const [callMutation, { loading: isLoading, error }] = useMutation(ForgotPasswordUpdate_Mutation, {
    context: { clientName: 'auth' },
    onCompleted: () => {
      router.push('/');
    },
  });

  const sendRequest = (e: any) => {
    e.preventDefault();
    if (!password || !token) return;
    callMutation({ variables: { newPassword: password, code: token } })
      // do nothing with the error as we use the message from the mutation
      .catch(() => {});
  };

  const lazyRequest = { sendRequest, isLoading, error };

  return (
    <AuthenticationForm ctaName="Reset Password" lazyRequest={lazyRequest}>
      <Label htmlFor="password">New Password:</Label>
      <Input id="password" type="password" value={password} onChange={({ target: { value } }) => setPassword(value)} required />
    </AuthenticationForm>
  );
}
