import { useLazyQuery } from '@apollo/react-hooks';
import { Login_Query, _eStorageKey, _Store, _StoreTokens } from '@netfront/gelada-react-shared';
import cx from 'classnames';
import { Input, Label } from 'components/Form';
import Link from 'components/Link';
import gtag from 'ga-gtag';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { AuthenticationForm } from './AuthenticationForm';
import styles from './LoginForm.module.css';

export function LoginForm() {
  const router = useRouter();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [loginUser, { error, loading }] = useLazyQuery(Login_Query, {
    context: { clientName: 'auth' },
    onCompleted: (response) => {
      const {
        login: { refreshToken, token, user },
      } = response;

      const { id: userId } = user;

      if (token) {
        gtag('set', { user_id: userId });
        _Store(user, _eStorageKey.userData);
        _StoreTokens(token, refreshToken);
        window.location.href = '/dashboard';
      }
    },
  });

  const sendRequest = (e: any) => {
    e.preventDefault();

    if (!email || !password) {
      return;
    }

    loginUser({
      variables: {
        login: email,
        password: password,
      },
    });
  };

  const lazyRequest = { sendRequest, loading, error };

  return (
    <AuthenticationForm ctaName="Login" lazyRequest={lazyRequest}>
      <Label htmlFor="email">Email:</Label>
      <Input required id="email" type="email" value={email} onChange={({ target: { value } }) => setEmail(value)} />
      <Label htmlFor="password">Password:</Label>
      <Input required id="password" type="password" value={password} onChange={({ target: { value } }) => setPassword(value)} />
      <div className="m-t--large l-flex">
        <Link className="h-color--navy weight-700" href="/forgot-password">
          Forgot password?
        </Link>
      </div>
    </AuthenticationForm>
  );
}
