import React, { useEffect } from 'react';

import { toast } from 'react-toastify';

import Button from 'components/Button';

export function AuthenticationForm({ children, ctaName, lazyRequest: { sendRequest, isLoading, error } }: any): JSX.Element {
  useEffect(() => {
    const errorMessage = error?.message?.replace(/GraphQL error:/g, '');
    if (errorMessage) {
      toast.error(errorMessage || 'Sorry, something went wrong', { position: 'bottom-center' });
    }
  }, [error]);

  return (
    <form className="w-100" onSubmit={sendRequest}>
      {children}
      <div className="l-flex">
        <Button className="m-t--large m-l--auto" disabled={isLoading} size="large" type="submit">
          {ctaName}
        </Button>
      </div>
    </form>
  );
}
