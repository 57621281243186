import cx from 'classnames';
import Footer from 'components/Footer';
import Header from 'components/Header';
import { useIsAuthenticated } from 'lib/hooks/useIsAuthenticated';
import Head from 'next/head';
import React from 'react';
import styles from './Layout.module.css';

interface LayoutProps {
  children: React.ReactNode;
  classNames?: string[];
  title: string;
}

export function Layout({ children, classNames, title }: LayoutProps) {
  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      <Head>
        <title>{title} - Rethink My Drink</title>
      </Head>
      <main className={cx(styles['main'], classNames)}>
        <div className={styles['Layout']}>
          <Header showMenu={isAuthenticated} />
          <div className={cx('c-layout', styles['layout--internal'])}>{children}</div>
        </div>
        <Footer />
      </main>
    </>
  );
}
